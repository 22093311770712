import * as React from 'react';

import { ContainerWrapper } from './styled';

export const Container: React.FC<ContainerProps> = ({ children, padding, width }) => {
  return (
    <ContainerWrapper padding={padding} $width={width}>
      {children}
    </ContainerWrapper>
  );
};

type ContainerProps = {
  padding?: string;
  width?: string;
};
