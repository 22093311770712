import styled, { css } from 'styled-components';

import { Paragraph } from 'common/typography';
import { media } from 'styles/utils';

export const DropdownNavigationItem = styled(Paragraph)<DropdownNavigationItemProps>(
  ({ theme, isActive, isTransparent, isOpen }) => css`
    padding: 16px 24px;
    gap: 4px;
    cursor: pointer;
    position: relative;
    transition: 0.15s ease;
    color: ${isTransparent
      ? theme.colors.white
      : isActive
      ? theme.colors.babyBlue
      : theme.colors.nightBlue};

    ${media.desktop`
      & svg {
        display: block;
        fill: ${isTransparent ? theme.colors.white : theme.colors.nightBlue};
        transform: ${isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
        transition: 0.15s ease;
      }
    `}

    @media (hover: hover) {
      &:hover {
        color: ${isTransparent ? theme.colors.white60 : theme.colors.babyBlue};

        svg {
          fill: ${isTransparent ? theme.colors.white60 : theme.colors.babyBlue};
        }
      }
    }
  `,
);

type DropdownNavigationItemProps = {
  isActive?: boolean;
  isTransparent?: boolean;
  isOpen?: boolean;
};

export const DropdownContainer = styled.div<DropdownContainerProps>(
  ({ theme, isOpen }) => css`
    display: ${isOpen ? 'grid' : 'none'};
    position: absolute;
    z-index: 2;
    top: 84px;
    margin-right: 48px;
    grid-template-columns: repeat(2,1fr);
    background-color: ${theme.colors.white};
    border-radius: 8px;
    border: ${theme.border.default};

    & a {
      display: flex;
      justify-content: space-between;
    }
  `,
);

type DropdownContainerProps = {
  isOpen: boolean;
};

export const DropdownItem = styled(Paragraph)<DropdownItemProps>(
  ({ theme }) => css`
    position: relative;
    color: ${theme.colors.nightBlue};
    transition: 0.15s ease;

    & div {
      display: flex;
      flex-direction: column;
    }
  `,
);

type DropdownItemProps = {
  isTransparent?: boolean;
};

export const DropdownItemContainer = styled.div(
  ({ theme }) => css`
    transition: 0.15s ease;
    display: flex;
    justify-content: space-between;
    gap: 0px;

    svg {
      fill: ${theme.colors.nightBlue};
    }

    ${media.large`
      gap: 48px
    `}

  @media (hover: hover) {
    &:hover {
      p {
        color: ${theme.colors.babyBlue};
      }

      svg {
        fill: ${theme.colors.babyBlue};
      }
    }
  }
`,
);
