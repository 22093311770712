import * as React from 'react';

import ArrowRightIcon from 'vectors/arrow_right.svg';
import ArrowDownIcon from 'vectors/chevron_down.svg';
import { Link } from 'common/interaction';
import { useOutsideClick } from 'hooks';

import {
  DropdownNavigationItem, DropdownContainer, DropdownItem,
  DropdownItemContainer,
} from './styled';

export const Dropdown = ({ title, slugs, dropdownOpen, setDropdownOpen, setIsNavigationOpen, isTransparent }: DropdownProps) => {
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  useOutsideClick(dropdownRef, () => setDropdownOpen(false));

  const closeMenus = () => {
    setIsNavigationOpen(false);
    setDropdownOpen(false);
  }

  return (
    <>
      <DropdownNavigationItem
        weight={900}
        key={title}
        isTransparent={isTransparent}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        isOpen={dropdownOpen}
        ref={dropdownRef}
      >
        {title}
        <ArrowDownIcon />
      </DropdownNavigationItem>
      <DropdownContainer
        isOpen={dropdownOpen}
      >
        {slugs.map((item) => (
          <DropdownItemContainer key={item.title}>
            <Link to={`/${item.dropdownSlug}`} onClick={() => closeMenus()}>
              <span>
                <DropdownItem
                  weight={900}
                  key={item.title}>
                    {item.title}
                </DropdownItem>
                {item.dropdownDescription}
              </span>
              <ArrowRightIcon />
            </Link>
          </DropdownItemContainer>
        ))}
      </DropdownContainer>
    </>
  )};

type DropdownProps = {
  title?: string;
  slugs: { title?: string | undefined; dropdownSlug?: string | undefined; dropdownDescription?: string | undefined; }[]
  isTransparent?: boolean;
  dropdownOpen: boolean;
  setDropdownOpen: (boolean: boolean) => void;
  setIsNavigationOpen: (boolean: boolean) => void;
};
