import * as React from 'react';

import { Container, Group } from 'common/layout';

import { HeaderContainer } from './styled';

export const Header: React.FC<HeaderProps> = ({ children, isTransparent }) => {
  return (
    <HeaderContainer isTransparent={isTransparent}>
      <Container>
        <Group justify="space-between">{children}</Group>
      </Container>
    </HeaderContainer>
  );
};

type HeaderProps = {
  isTransparent?: boolean;
};
