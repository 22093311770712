import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import { Link } from 'common/interaction';

export const PageLayoutContainer = styled.div<PageLayoutContainerProps>(
  ({ withoutPadding, withTransparentHeader }) => css`
    height: auto;
    margin-top: ${withTransparentHeader ? '0' : '76px'};
    padding: ${withoutPadding ? '0' : '96px 0'};

    ${media.tablet`
      margin-top: ${withTransparentHeader ? '0' : '80px'};
      min-height: calc(100% - 420px);
    `}
  `,
);

type PageLayoutContainerProps = {
  withoutPadding?: boolean;
  withTransparentHeader?: boolean;
};

export const PageLayoutLogo = styled(Link)`
  height: 24px;
  width: 144px;
`;
