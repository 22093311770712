import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import { HamburgerButton } from 'common/interaction/Hamburger/styled';
import { PageLayoutLogo } from 'layouts/PageLayout/styled';

export const HeaderContainer = styled.header<HeaderContainerProps>(
  ({ theme, isTransparent }) => css`
    height: 76px;
    display: flex;
    align-items: center;
    border-bottom: ${isTransparent ? '0' : theme.border.default};
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background: ${isTransparent
      ? 'linear-gradient(180deg, rgba(0,0,0,0.4) 0%, rgba(255,255,255,0) 90%);'
      : theme.colors.white};
    z-index: 9;
    transition: background-color 0.15s ease;

    ${media.tablet`
      height: 80px;
    `}

    ${PageLayoutLogo} {
      & > svg:first-of-type {
        display: ${isTransparent ? 'none' : 'block'};
      }

      & > svg:last-of-type {
        display: ${isTransparent ? 'block' : 'none'};
      }
    }

    ${HamburgerButton} {
      color: ${isTransparent ? theme.colors.white : theme.colors.nightBlue};

      span {
        span,
        &:after,
        &:before {
          background-color: ${isTransparent ? theme.colors.white : theme.colors.nightBlue};
        }
      }
    }
  `,
);

type HeaderContainerProps = {
  isTransparent?: boolean;
};
