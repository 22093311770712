import styled from 'styled-components';

import { media } from 'styles/utils';

export const GeneralSidebar = styled.aside`
  width: 100%;
  flex-shrink: 0;

  ${media.tablet`
    width: 50%;
  `}
`;
