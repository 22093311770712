import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const ContainerWrapper = styled.div<ContainerWrapperProps>(
  ({ padding, $width }) => css`
    width: 100%;
    padding: ${padding || '0 24px'};

    ${media.desktop`
      padding: ${padding || '0 48px'};
      margin: 0 auto;
      max-width: ${$width || '1513px'};
    `}
  `,
);

type ContainerWrapperProps = {
  padding?: string;
  $width?: string;
};
