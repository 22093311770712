import * as i from 'types';
import * as React from 'react';

import { isTypeNavigationList, isTypePage } from 'services';
import { Hamburger } from 'common/interaction';
import { Navigation } from 'common/layout';

import { MainNavigationContainer } from './styled';

export const MainNavigation = ({
  navigation,
  isNavigationOpen,
  setIsNavigationOpen,
  isTransparent,
}: MainNavigationProps) => {

  /* This function formats all slugs of nested Navigation list inside
  the Navigation list so that it can be used in a dropdown. */
  const formatNavigationList = (item) => {
    if(isTypeNavigationList(item.fields) === false) return null;

    return item.fields.navigationItems.map((item) => {
      const dropdownSlug = isTypePage(item.fields) ? item.fields.seo.fields.slug : null;
      const dropdownDescription = isTypePage(item.fields) ? item.fields.seo?.fields.metaDescription : null;

      return {
        title: item.fields.title,
        dropdownDescription,
        dropdownSlug,
      };
    }
  )}

  /* This function returns the slug of all Contentful pages
  and Navigation items present in the Navigation list. */
  const getSlug = (item) => {
    if (isTypePage(item.fields)) return item.fields.seo.fields.slug;
    return item.fields.slug;
  }

  const navigationItems = navigation.fields.navigationItems.map((item) => {
    const slug = getSlug(item)

    return {
      title: item.fields.title,
      nestedNavigation: formatNavigationList(item),
      slug,
    };
  })

  return (
    <MainNavigationContainer isOpen={isNavigationOpen}>
      {navigation.fields.navigationItems.length > 0 && (
        <Navigation
          items={navigationItems}
          isTransparent={isTransparent}
          setIsNavigationOpen={setIsNavigationOpen}
        />
      )}
      <Hamburger onClick={setIsNavigationOpen} isOpen={isNavigationOpen} />
    </MainNavigationContainer>
  );
};

type MainNavigationProps = {
  navigation: i.TypeNavigationList;
  isNavigationOpen: boolean;
  setIsNavigationOpen: (open: boolean) => void;
  isTransparent?: boolean;
};
