import styled, { css } from 'styled-components';

export const Divider = styled.div<DividerProps>(
  ({ theme, offset }) => css`
    height: 1px;
    width: 100%;
    margin: ${offset || 8}px 0;
    background-color: ${theme.colors.nightBlue20};
  `,
);

type DividerProps = {
  offset?: number;
};
