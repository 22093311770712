import * as React from 'react';

import { useDevice, useRouter } from 'hooks';
import { formatMobileMenuItems } from 'services';
import ArrowRightIcon from 'vectors/arrow_right.svg';
import { Link } from 'common/interaction';

import { Dropdown } from './Dropdown';
import {
  NavigationWrapper, NavigationItem,
} from './styled';

export const Navigation = ({ items, setIsNavigationOpen, isTransparent }: NavigationProps) => {
  const router = useRouter();
  const { isMobile } = useDevice()
  const currentPath = router.asPath.replaceAll('/', '');
  const [dropdownOpen, setDropdownOpen] = React.useState(false);

  const closeMenus = () => {
    setIsNavigationOpen(false);
    setDropdownOpen(false);
  }

  return (
    <NavigationWrapper>
      {isMobile && formatMobileMenuItems(items).map((item) => (
        <NavigationItem
          weight={900}
          key={item.title}
          isTransparent={isTransparent}
        >
          <Link to={`/${item.slug}`} onClick={() => closeMenus()}>
            {item.title}
          </Link>
          <ArrowRightIcon />
        </NavigationItem>
      ))}
      {!isMobile && items.map((item) => (
        item.nestedNavigation ? 
          (
            <Dropdown 
              key={item.title}
              slugs={item.nestedNavigation}
              title={item.title}
              isTransparent={isTransparent}
              setIsNavigationOpen={setIsNavigationOpen}
              setDropdownOpen={setDropdownOpen}
              dropdownOpen={dropdownOpen}
            />
          ) : (
            <NavigationItem
              weight={900}
              key={item.title}
              isActive={currentPath === item.slug?.replaceAll('/', '')}
              isTransparent={isTransparent}
            >
              <Link to={`/${item.slug}`} onClick={() => closeMenus()}>
                {item.title}
              </Link>
              <ArrowRightIcon />
            </NavigationItem>
          )
        )
      )}
    </NavigationWrapper>
  );
};

type NavigationProps = {
  items: {
    title?: string;
    nestedNavigation?: { title?: string, dropdownSlug?: string, dropdownDescription?: string }[];
    slug?: string;
  }[];
  isTransparent?: boolean;
  setIsNavigationOpen: (boolean: boolean) => void;
};
