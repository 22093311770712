import * as i from 'types';
import * as React from 'react';

import { isTypePage } from 'services';
import { Link } from 'common/interaction';
import { Heading, Paragraph } from 'common/typography';

import {
  FooterWrapper,
  FooterColumnContainer,
  FooterInnerContainer,
  FooterExternalIcon,
} from './styled';

const FooterContainer: React.FC<FooterContainerProps> = ({ children, isOnlyOneColumn }) => {
  return (
    <FooterWrapper>
      <FooterInnerContainer isOnlyOneColumn={isOnlyOneColumn}>{children}</FooterInnerContainer>
    </FooterWrapper>
  );
};

type FooterContainerProps = {
  isOnlyOneColumn?: boolean;
};

const FooterColumn = ({ data }: FooterColumnProps) => {
  return (
    <FooterColumnContainer>
      <Heading as="h4" color="babyBlue" margin="0">
        {data.title}
      </Heading>
      {data.navigationItems.map((item, index) => {
        const isExternal = !isTypePage(item.fields);
        const to = isTypePage(item.fields) ? `/${item.fields.seo.fields.slug}` : item.fields.slug;

        return (
          to && <Link to={to} key={`${index}_${to}`}>
            <Paragraph weight={900} color="white">
              {item.fields.title}
              {isExternal && <FooterExternalIcon />}
            </Paragraph>
          </Link>
        );
      })}
    </FooterColumnContainer>
  );
};

export const Footer = {
  Container: FooterContainer,
  Column: FooterColumn,
};

type FooterColumnProps = {
  data: i.TypeNavigationListFields;
};
