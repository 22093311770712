import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const MainNavigationContainer = styled.div<MainNavigationContainerProps>(
  ({ isOpen }) => css`
    & > nav {
      display: ${isOpen ? 'block' : 'none'};

      ${media.tablet`
        display: flex;
      `}
    }
  `,
);

type MainNavigationContainerProps = {
  isOpen: boolean;
};
