import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import { Paragraph } from 'common/typography';

export const NavigationWrapper = styled.nav(
  ({ theme }) => css`
    color: ${theme.colors.nightBlue};
    background-color: ${theme.colors.white};
    position: absolute;
    top: 76px;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9;
    height: calc(100vh - 76px);
    padding: 0 24px;
    transition: background-color 0.15s ease;

    ${media.tablet`
      display: flex;
      align-items: center;
      height: auto;
      padding: 0;
      position: static;
      background-color: transparent;
    `}

    & > p {
      height: 72px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: ${theme.border.default};

      ${media.tablet`
        border: 0;
        height: auto;
      `}
    }

    & a {
      text-decoration: none;
      color: inherit;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      transition: color 0.15s ease;

      ${media.tablet`
        padding: 16px 24px;

        @media (hover: hover) {
          &:hover {
            color: ${theme.colors.babyBlue};
          }
        }
      `}
    }
  `,
);

export const NavigationItem = styled(Paragraph)<NavigationItemProps>(
  ({ theme, isActive, isTransparent }) => css`
    position: relative;
    color: ${isTransparent
      ? theme.colors.white
      : isActive
      ? theme.colors.babyBlue
      : theme.colors.nightBlue};
    
    svg {
      fill: ${theme.colors.nightBlue};
    }

    ${media.tablet`
      & > svg {
        display: none;
      }
    `}

    @media (hover: hover) {
      &:hover {
        color: ${isTransparent ? theme.colors.white60 : theme.colors.babyBlue};

        a {
          color: ${isTransparent ? theme.colors.white60 : theme.colors.babyBlue};
        }
      }
    }
  `,
);

type NavigationItemProps = {
  isActive?: boolean;
  isTransparent?: boolean;
};
