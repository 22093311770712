import styled from 'styled-components';

export const Stack = styled.div<StackProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ spacing }) => spacing || '48'}px;
`;

type StackProps = {
  spacing?: number;
};
