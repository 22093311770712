import * as i from 'types';
import * as React from 'react';

import { Footer } from 'common/layout';

export const MainFooter = ({ footer }: MainFooterProps) => {
  return (
    <Footer.Container isOnlyOneColumn={footer.fields.navigation?.length === 1}>
      {footer.fields.navigation?.map((navItem) => (
        <Footer.Column data={navItem.fields} key={navItem.sys.id} />
      ))}
    </Footer.Container>
  );
};

type MainFooterProps = {
  footer: i.TypeLayoutFooter;
};
