import styled, { css } from 'styled-components';

export const CardSectionHeader = styled.div(
  ({ theme }) => css`
    padding: 24px;
    border-bottom: ${theme.border.default};
  `,
);

export const CardSectionText = styled.div(
  ({ theme }) => css`
    border-bottom: ${theme.border.default};
    padding-bottom: 16px;
  `,
);

export const CardSectionContainer = styled.section(
  ({ theme }) => css`
    background-color: ${theme.colors.white};
    border-radius: 8px;
    width: 100%;
  `,
);

export const CardSectionImage = styled.div(
  ({ theme }) => css`
    border-bottom: ${theme.border.default};
    padding: 24px;
  `,
);

export const CardSectionBody = styled.div`
  padding: 16px 24px 24px;
`;
