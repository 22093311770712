import styled, { css } from 'styled-components';

import { media } from 'styles/utils';
import ExternalIcon from 'vectors/external.svg';

export const FooterWrapper = styled.footer(
  ({ theme }) => css`
    background-color: ${theme.colors.nightBlue};
    width: 100%;
  `,
);

export const FooterInnerContainer = styled.div<FooterInnerContainerProps>(
  ({ isOnlyOneColumn }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 48px 24px;

    ${media.tablet`
      flex-direction: row;
      gap: 48px;
    `}

    ${media.desktop`
      margin: 0 auto;
      padding: 48px;
    `}

    ${media.large`
      max-width: 1513px;
    `}

    ${isOnlyOneColumn &&
    css`
      justify-content: center;

      & h4 {
        display: none;
      }

      ${FooterColumnContainer} p {
        text-align: center;
        justify-content: center;
      }
    `}
  `,
);

type FooterInnerContainerProps = {
  isOnlyOneColumn?: boolean;
};

export const FooterColumnContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    flex-shrink: 1;

    ${media.tablet`
      width: 25%;
    `}

    & a {
      text-decoration: none;

      & > p {
        transition: color 0.15s ease;
        display: flex;
        align-items: center;
        gap: 8px;
      }

      @media (hover: hover) {
        &:hover {
          & p {
            color: ${theme.colors.babyBlue};

            & svg {
              fill: ${theme.colors.babyBlue};
            }
          }
        }
      }
    }
  `,
);

export const FooterExternalIcon = styled(ExternalIcon)(
  ({ theme }) => css`
    transition: fill 0.15s ease;
    width: 24px;
    fill: ${theme.colors.white};
  `,
);
