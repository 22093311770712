import styled, { css } from 'styled-components';

import { media } from 'styles/utils';

export const Group = styled.div<GroupProps>(
  ({ align, justify, gap, isDesktopOnly, isLargeDesktopOnly, margin, flexWrap }) => css`
    display: flex;
    flex-direction: ${isDesktopOnly ? 'column' : 'row'};
    align-items: ${align || 'center'};
    gap: ${gap || 0}px;
    margin: ${margin || 0};
    justify-content: ${justify || 'flex-start'};
    flex-wrap: ${flexWrap ? 'wrap' : 'nowrap'};

    ${media.tablet`
      flex-direction: ${isLargeDesktopOnly ? 'column' : 'row'};
    `}

    ${media.large`
      flex-direction: row;
    `}
  `,
);

type GroupProps = {
  align?: 'flex-start' | 'flex-end' | 'space-between' | 'space-around';
  justify?: 'flex-start' | 'flex-end' | 'space-between' | 'space-around';
  isDesktopOnly?: boolean;
  isLargeDesktopOnly?: boolean;
  gap?: number;
  margin?: string;
  flexWrap?: boolean;
};
